import { POST, PATCH, DELETE } from 'utils/requests.utils'
import { Response, HTTPResponse } from 'types/responses'

import { TicketDate } from 'shop/types/ticket'
import { PricingPlan } from 'types/pricing-plans'

const BASE_URL = '/cabinet/ticket_date_upgrade_mappings'

export interface PricesAttribute {
  position: string
  pricingPlanId: string
  recommended: boolean
}

export interface UpgradeMappings {
  ticket: Ticket
  product: Product
  pricingPlans: PricingPlan[]
  ticketDates: TicketDate[]
}

export interface TicketDateUpgradeMapping extends TicketDate {
  upgradeMappings: UpgradeMappings
}

interface TicketDateUpgradeMappingData {
  upgradeMappings: {
    ticketDateUpgradeId: string
  }[]
  pricesAttributes: PricesAttribute[]
}

interface TicketDateUpgradeMappingDeleteData {
  ticketDateUpgradeId: string | number
}

interface TicketDateUpgradeMappingCreateData extends TicketDateUpgradeMappingData {
  ticketDateId: string
}

interface Product {
  id: number
  name: string
}

interface Ticket {
  id: number
  name: string
  active: boolean
  canSell: boolean
  online: boolean
  soldOut: boolean
  ticketLocationName: string
}

export const createItem = (data: TicketDateUpgradeMappingCreateData) =>
  POST<Response<TicketDateUpgradeMapping>>(BASE_URL, data)

export const updateItem = (id, data: TicketDateUpgradeMappingData) =>
  PATCH<Response<TicketDateUpgradeMapping>>(`${BASE_URL}/${id}`, data)

export const deleteItem = (id, data: TicketDateUpgradeMappingDeleteData) =>
  DELETE<HTTPResponse>(`${BASE_URL}/${id}`, data)
