import { action, computed, observable, toJS, makeObservable } from 'mobx'

import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'

import {
  getDefaultProductsList,
  DEFAULT_PRODUCT,
  DEFAULT_LESSONS,
  getDefaultPopularProductsList,
  DEFAULT_ORDER,
} from 'constants/contentPage.constants'
import { THEME_TYPE } from 'constants/general.constants'
import { LANGUAGES_OPTIONS_KEYS } from 'constants/locale.constants'
import { DEFAULT_PAGE, DEFAULT_SHOP_ITEMS_PER_PAGE } from 'constants/pagination.constants'

import { DEFAULT_INPUT_FIELDS } from 'shared/components/page-builder/constants/pageBuilder.constants'

import * as api from '../api/contentPage.api'
import { lessonsForModalsStore } from './lessons.store'

export class ContentPageStore {
  @observable loading = false
  @observable customLoadingEnabled = false
  @observable customLoading = true
  @observable membershipProducts = getDefaultProductsList()
  @observable sellerProducts = getDefaultProductsList()
  @observable popularProducts = getDefaultPopularProductsList()
  @observable popularProductsLoading = false
  @observable product = DEFAULT_PRODUCT
  @observable lessons = DEFAULT_LESSONS
  @observable view = ELOPAGE_CABINETS.cabinet
  @observable themeType = THEME_TYPE.shop
  @observable videoCodes = new Map()
  @observable loadingBlockId = null
  @observable funnelHandled = false

  @observable data = {
    id: null,
    contentBlocks: [],
  }

  @observable contentBlocks = []

  @observable pagination = {
    page: DEFAULT_PAGE,
    per: DEFAULT_SHOP_ITEMS_PER_PAGE,
    total: 0,
  }

  @computed get seller() {
    const avatarUrl =
      this.root.userStore.item.avatar &&
      (this.root.userStore.item.avatar.s200 || this.root.userStore.item.avatar.original)
    return {
      ...this.root.sellerStore.item,
      avatarUrl,
    }
  }

  @action setLoadingBlockId = (blockId) => (this.loadingBlockId = blockId)

  @action toggleCustomLoading = () => {
    this.customLoadingEnabled = !this.customLoadingEnabled
  }

  setProductsCustomLoading = (value) => this.root.productsStore.toggleCustomLoading(value)

  @action resetContentPageData = () => {
    this.data = {
      id: null,
      contentBlocks: [],
    }
  }

  @action fetchProducts = async (data) => {
    this.toggleLoading(true)
    this.root.productsStore.resetData()
    const resp = await this.root.productsStore.fetchFullList(data)
    this.toggleLoading(false)
    return resp
  }

  @action fetchPopularProducts = async (data) => {
    this.toggleLoading(true)
    const resp = await this.root.productsStore.fetchFullList({
      ...data,
      canBeSoldViaShop: true,
    })
    const { list = [] } = resp.data || {}
    this.popularProducts = list
    this.toggleLoading(false)
    return resp
  }

  @action handlePaginationChange = () => {}

  @action toggleLoading = (loading) => (this.loading = loading)

  @action setThemeType = (type) => (this.themeType = type)

  @action refetchContentPage = (data) => this.fetchContentPage(this.data.id, data)

  @action fetchContentPage = async (id, data) => {
    const { hideLoading = false, ...restData } = data || {}

    if (!hideLoading) {
      this.toggleLoading(true)
    }

    const resp = await api.fetchContentPage(id, restData)
    this.processDataResponse(resp)

    if (!hideLoading) {
      this.toggleLoading(false)
    }

    return resp
  }

  @action createItem = async (data) => {
    this.toggleLoading(true)
    const resp = await api.createItem(data || this.item)
    this.toggleLoading(false)
    return resp
  }

  @action deleteItem = async (id, data) => {
    this.toggleLoading(true)
    const resp = await api.deleteItem(id, data)
    this.toggleLoading(false)
    return resp
  }

  @action createPageFromTemplate = async (id, data) => {
    this.toggleLoading(true)
    const resp = await api.createPageFromTemplate(id, data)
    this.toggleLoading(false)
    return resp
  }

  @action createContentBlock = async (parentId, blockForm, position, data, contentPageId) => {
    this.toggleLoading(true)
    const resp = await api.createContentBlock({
      parentId,
      contentPageId: this.data.id || contentPageId,
      coverId: data?.coverId,
      form: blockForm,
      ...(blockForm === 'contact_form' && {
        contactFormAttributes: {
          formFieldsAttributes: DEFAULT_INPUT_FIELDS,
          name: data?.formName,
        },
      }),
      position,
      data,
    })
    this.processDataResponse(resp)
    this.root.lessonsStore.blocksModified()
    this.toggleLoading(false)

    return resp
  }

  @action createItemFromTemplate = async (id, data) => {
    this.toggleLoading(true)
    const resp = await api.createItemFromTemplate(id, {
      ...data,
      contentPageId: this.data.id,
    })
    this.processDataResponse(resp)
    this.root.lessonsStore.blocksModified()
    this.toggleLoading(false)
  }

  @action removeContentBlock = async (blockId) => {
    this.toggleLoading(true)
    const resp = await api.removeContentBlock(blockId, {
      contentPageId: this.data.id,
    })
    this.processDataResponse(resp)
    this.root.lessonsStore.blocksModified()
    this.toggleLoading(false)

    return resp
  }

  updateContentBlock = async (blockId, data) => {
    this.toggleLoading(true)
    this.setLoadingBlockId(blockId)
    const resp = await api.updateContentBlock(blockId, {
      contentPageId: this.data.id,
      ...data,
    })
    this.processDataResponse(resp)
    this.root.lessonsStore.blocksModified()
    this.toggleLoading(false)
    this.setLoadingBlockId(null)

    return resp
  }

  @action duplicateContentBlock = async (blockId) => {
    this.toggleLoading(true)
    const resp = await api.duplicateContentBlock(blockId, {
      contentPageId: this.data.id,
    })
    this.processDataResponse(resp)
    this.root.lessonsStore.blocksModified()
    this.refetchContentPage()
    this.toggleLoading(false)

    return resp
  }

  @action processDataResponse = (resp) => {
    if (resp.success) {
      this.data = toJS({
        ...this.data,
        ...resp.data,
        id: resp.data.id,
        contentBlocks: [...resp.data.contentBlocks],
      })
    }
  }

  @action setFunnelHandled = (value) => (this.funnelHandled = value)

  @computed get embeddableStore() {
    return this.root.embeddableItemsStore
  }

  @computed get lessonsStore() {
    return lessonsForModalsStore
  }

  @computed get courseThemesStore() {
    return this.root.courseThemesStore
  }

  @computed get affiliateProgramsStore() {
    return this.root.affiliateProgramsStore
  }

  @computed get displayCounterVars() {
    return this.root.lessonsStore.activeTab === 'beforeStartDate'
  }

  @action setApp = (key, value) => this.root.sellerStore.setAppKey(key, value)

  @computed get wistiaProjectPass() {
    return this.root.sellerStore.item.wistiaProjectPass
  }

  @computed get wistiaProjectId() {
    return this.root.sellerStore.item.wistiaProjectId || this.root.sellerStore.createWistiaProject()
  }

  @computed get availableLanguages() {
    const { item: { prefs = {} } = {} } = this.root.shopThemeStore
    const themeSettings = toJS(prefs)
    if (!themeSettings.languages || !themeSettings.languages.length) {
      return LANGUAGES_OPTIONS_KEYS
    }

    return themeSettings.languages
  }

  @action setVideoCode = (contentBlockId, blockCodeId, newBlockCodeData) => {
    const blockVideoCodes = this.videoCodes.get(contentBlockId)
    const blockCodeData = this.getVideoCodeData(contentBlockId, blockCodeId)
    this.videoCodes.set(contentBlockId, {
      ...blockVideoCodes,
      [blockCodeId]: {
        ...blockCodeData,
        ...newBlockCodeData,
      },
    })
  }

  getVideoCodeData = (contentBlockId, blockCodeId) => (this.videoCodes.get(contentBlockId) || {})[blockCodeId] || {}

  @action setVideoCodeValue = (contentBlockId, blockCodeId, value) => {
    const codeData = this.getVideoCodeData(contentBlockId, blockCodeId)
    const passed = String(codeData.code) === String(value)
    this.setVideoCode(contentBlockId, blockCodeId, {
      value,
      passed,
    })
  }

  fetchVideoCodes = (data, blockCodes = []) => {
    blockCodes.forEach(({ name, id }) =>
      this.setVideoCode(data.contentBlockId, id, {
        id,
        name,
      })
    )
  }

  @action deleteBlockVideoCodes = (blockId) => this.videoCodes.delete(blockId)

  @computed get themePages() {
    return this.themeType === THEME_TYPE.shop
      ? this.root.shopThemeStore.item.themePages
      : this.root.membershipThemesStore.item.themePages
  }

  getProductLink = () => '#'

  isAppActive = (key) => this.root.sellerStore.isAppActive(key)

  setAppKey = () => this.root.sellerStore.setAppKey()

  // eslint-disable-next-line
  fetchProduct = async () => ({})

  @computed get isLessonStatic() {
    return this.root.lessonsStore.item.static
  }

  @computed get hideVideoCodes() {
    return this.isLessonStatic || !this.isAppActive('video_captions')
  }

  createBlockFormField = api.createBlockFormField
  updateBlockFormFields = api.updateBlockFormFields
  deleteBlockFormField = api.deleteBlockFormField
  updateContactFormBlock = api.updateContactFormBlock

  convertToPrice = this.root?.currenciesStore.convertToPrice

  fetchFullCountriesList = () => {}

  countriesList = []

  ordersStore = {
    item: {},
    data: {},
    list: [DEFAULT_ORDER],
  }

  @computed get user() {
    return this.root.userStore.item
  }

  constructor(rootStore) {
    this.root = rootStore
    makeObservable(this)
  }
}
