import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import { createOrdersApi, OrdersApi } from '../api/orders-v1.1.api'

export class CabinetOrdersV1S1Store extends SharedStore<any> {
  storeName = 'CabinetOrdersV1S1Store'
  storeName2 = 'OrdersStore'
  declare childApi: OrdersApi

  constructor() {
    super()
    this.childApi = createOrdersApi()
    makeObservable(this)
  }
}
