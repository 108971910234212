import { GET, POST, PUT, DELETE, PATCH } from 'utils/requests.utils'
import { Response, PaginatedResponse } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/cabinet/claims'

export interface Claim {
  id: number | string
  dateResolved: Nullable<string>
  dunningStatus: string
  emailCount: number
  strategyType: string
  inkassoStatus: string
  inkassoBufferEndDate: string
  invoiceId: Nullable<number>
  invoiceIssuedAt: Nullable<string>
  nextEmailDate: Nullable<string>
  outcomeStatus: string
  payerId: number
  sellerId: number
  strategyStatus: string
  strategyStage: string
  createdAt: string
  updatedAt: string
  orderRateId: number
  notes: string
  notCollectedReason: string
  invoice: {
    invoiceNumber?: string
  }
  prevClaimEmailCount: number | null
  order: {
    orderId?: number
    pricingPlan?: string
    pastDueDate?: Nullable<string>
    daysOverdue?: Nullable<number>
    dueAmount?: Nullable<string>
    paidAmount?: Nullable<string>
    outstandingDebt?: Nullable<number>
    collectedDebt?: Nullable<number>
    orderAmount?: string
    currencyId: number
    buyerCountryCode?: string
    paymentForm: string
    provider: string
    cancelled: boolean
  }
  payer: {
    id: number
    companyName: Nullable<string>
    name: string
    business: boolean
  }
  seller: {
    preferredStrategy
  }
  inkassoCollectionWasUnsuccessful?: boolean
}

export const fetchList = (data) => GET<PaginatedResponse<Claim[]>>(BASE_URL, data)

export const fetchItem = (id: number) => GET<Response<Claim>>(`${BASE_URL}/${id}`)

export const createItem = (data) => POST<Response<Claim>>(BASE_URL, data)

export const updateItem = (id: number, data) => PUT<Response<Claim>>(`${BASE_URL}/${id}`, data)

export const deleteItem = (id: number) => DELETE(`${BASE_URL}/${id}`)

export const createBulk = (data: Claim[]) => POST<Response<Claim[]>>(`${BASE_URL}/bulk_creations`, data)

export const singleClaim = (data: Claim) => POST<Response<Claim>>(`${BASE_URL}`, data)

export const stopResolveClaim = (id: number, data: { notes: string }) =>
  PATCH<Response<Claim>>(`${BASE_URL}/resolutions/${id}`, data)

export const stopResolveBulkClaim = (data: { ids: number[]; notes: string }) =>
  PATCH<Response<Claim>>(`${BASE_URL}/resolutions/bulk_update`, data)
