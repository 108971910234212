import { GET } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

import { AffiliateMarketplaceOffer } from 'shared/components/affiliate-marketplace/types/types'

const BASE_URL = '/cabinet/affiliate_marketplace/offers'

export const fetchList = (data) => GET<PaginatedResponse<AffiliateMarketplaceOffer>>(BASE_URL, data)

export const fetchItem = (id: string | number, data) =>
  GET<Response<AffiliateMarketplaceOffer>>(`${BASE_URL}/${id}`, data)
