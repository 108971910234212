import { GET, POST, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/transfers'
const BASE_URL_V1S1 = '/v1.1/cabinet/transfers'

export const fetchList = (data) => GET(BASE_URL_V1S1, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const fetchBalances = () => GET(`${BASE_URL}/balances.json`)

export const refund = (id, data) => POST(`${BASE_URL}/${id}/refund`, data)

export const cancelBankWire = (id, data = {}) => POST(`${BASE_URL}/${id}/cancel_bank_wire`, data)

export const markAsRefunded = (id) => POST(`${BASE_URL}/${id}/mark_as_refunded`)

export const extendAccess = (id, data) => POST(`${BASE_URL}/${id}/extend_access`, data)

export const fetchSummary = (data) => GET(`${BASE_URL}/summary`, data)

export const confirmBankWire = (id) => POST(`${BASE_URL}/${id}/success`)

export const fetchPartiallyPaid = (data) => GET(`${BASE_URL}/partially_paid`, data)
