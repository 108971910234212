import { useDynamicConfig, useExperiment, useFeatureGate } from '@statsig/react-bindings'
import type { StatsigClient } from '@statsig/js-client'

export class ExperimentsStore {
  storeName = 'ExperimentsStore'
  private client: StatsigClient
  private isActive = false

  setClient(client: StatsigClient) {
    this.client = client
    this.isActive = true
  }

  useExperiment(name: string) {
    return useExperiment(name)
  }

  useFeatureGate(name: string) {
    return useFeatureGate(name)
  }

  getFeatureGate(name: string) {
    return this.client.getFeatureGate(name)
  }

  getFeatureGateValue(name: string) {
    return this.client.getFeatureGate(name).value
  }

  useDynamicConfig(name: string) {
    return useDynamicConfig(name)
  }

  logEvent(eventName: string, value: string, metadata?: Record<string, string>) {
    if (!this.isActive) {
      return
    }
    this.client.logEvent(eventName, value, metadata)
  }
}
