import { GET, POST, PUT } from 'utils/requests.utils'
import { Nullable } from '../../../../types/helpers'
import { Response } from '../../../../types/responses'

const BASE_URL = '/cabinet/settings/payment_setting'
const CURRENCIES_URL = `${BASE_URL}/payment_setting_currencies`

export interface PaymentSetting {
  id: number
  name: Nullable<string>
  apiSettings: PaymentApiSetting
  applePayProvider: Nullable<string>
  bankWireProvider: Nullable<string>
  cardProvider: Nullable<string>
  directBankLegitimationId: Nullable<number>
  directDebitUkProvider: Nullable<unknown>
  elopageConnectLegitimationId: Nullable<number>
  firstProviderActivated: Nullable<string>
  firstProviderActivatedAt: Nullable<string>
  googlePayProvider: Nullable<string>
  lemonWayLegitimationId: Nullable<number>
  mangoPayLegitimationId: number
  p24Provider: Nullable<string>
  payPalProvider: Nullable<string>
  profileId: number
  profileType: string
  restV2From: string
  sepaProvider: Nullable<string>
  sofortProvider: Nullable<string>
  createdAt: string
  updatedAt: string
}

export interface PaymentApiSetting {
  payPal?: PayPalSetting
  stripe?: StripeSetting
  mangoPay: PaymentGeneralSetting
  directBank?: PaymentGeneralSetting
  elopageConnect?: ElopageConnectSetting
  lemonWay?: PaymentGeneralSetting
}

export interface PayPalSetting {
  nvp?: PayPalNvpSetting
  rest?: PayPalRestSetting
}

export interface PayPalNvpSetting {
  user?: string
  active: boolean
  password?: string
  signature?: string
  valid: boolean
}

export interface PayPalRestSetting {
  v2: boolean
  email: string
  active: boolean
  clientId: string
  clientSecret: string
  valid: boolean
  vaultEnabled: boolean
}

export interface StripeSetting {
  p24?: boolean
  sepa?: boolean
  active: boolean
  applePay?: boolean
  googlePay?: boolean
  secretKey: string
  sofortSepa?: boolean
  publishableKey: string
  valid: boolean
}

export interface PaymentGeneralSetting {
  active: boolean
}

export interface ElopageConnectSetting {
  p24?: boolean
  active?: boolean
  applePay?: boolean
  googlePay?: boolean
  bankWire?: boolean
  sepa?: boolean
  sofortSepa?: boolean
  bankWireCountry: string
}

/** BASE_URL */
export const fetchItem = () => GET<Response<PaymentSetting>>(BASE_URL)
export const createItem = (data) => POST(BASE_URL, data)
export const updateItem = (data) => PUT(BASE_URL, data)
/** BASE_URL */

/** CURRENCIES_URL */
export const fetchPaymentSettingCurrenciesList = (data) => GET(CURRENCIES_URL, data)
export const fetchPaymentSettingCurrency = (id, data) => GET(`${CURRENCIES_URL}/${id}`, data)
export const createPaymentSettingCurrency = (data) => POST(CURRENCIES_URL, data)
/** CURRENCIES_URL */
